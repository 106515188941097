import { useRouter } from 'next/router'
import { useStoreState } from 'easy-peasy'

import { isRolInProcesoDeEvaluacion } from 'utils'

export default function Home() {
  const router = useRouter()

  const me = useStoreState((state) => state.me)
  const selectedProcesoEvaluacion = useStoreState((state) => state.selectedProcesoEvaluacion)

  if (me && selectedProcesoEvaluacion) {
    if (isRolInProcesoDeEvaluacion(me, selectedProcesoEvaluacion, 'Comité')) {
      router.push('/evaluacion_comite/area')
    } else if (isRolInProcesoDeEvaluacion(me, selectedProcesoEvaluacion, 'Supervisor')) {
      router.push('/validar')
    } else {
      router.push('/dashboard')
    }
  }

  return null
}
